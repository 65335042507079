<template>
  <v-container>
    <v-card style="padding: 20px">
      <v-row>
        <v-col
          v-for="(item, i) in items"
          :key="item.id"
          cols="12"
          md="3"
        >
          <v-card>
            <v-card-text>
              <div>Student</div>
              <p class="display-1 text--primary">
                {{ item.firstName + ' ' + item.lastName }}
              </p>
              <div class="text--primary">
                {{ item.description }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="success"
                :disabled="$store.getters.get_permissionLevel < 2"
                @click="deleteUser(i)"
              >
                Delete Student
              </v-btn>
              <v-btn
                text
                color="success"
                :disabled="$store.getters.get_permissionLevel < 2"
                @click="dialog = true; selected = item; statut = statsList[item.permission];"
              >
                Edit Student
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialog"
      style="overflow: hidden"
      max-width="50vw"
    >
      <v-card>
        <v-row justify="center">
          <v-col
            cols="12"
            lg="6"
            md="9"
            sm="9"
          >
            <v-slide-y-transition appear>
              <v-card
                class="pa-3 pa-md-5 mx-auto"
                light
              >
                <span class="text-center display-3">
                  Edit student
                </span>

                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    sm
                  >
                    <div class="text-center">
                      <div class="my-2" />
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected.firstName"
                            :rules="[rules.required]"
                            label="First Name"
                            maxlength="20"
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected.lastName"
                            :rules="[rules.required]"
                            label="Last Name"
                            maxlength="20"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="selected.username"
                            label="Username"
                            disabled
                            :rules="[rules.required, rules.min, rules.max]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="selected.password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required]"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="Password"
                            counter
                            @click:append="show1 = !show1"
                          />
                        </v-col>
                        <v-col
                          class="d-flex"
                          cols="12"
                          sm="6"
                        >
                          <v-select
                            v-model="statut"
                            :items="statsList"
                            label="I am a..."
                            @change="selected.permission = statsList.indexOf(statut)"
                          />
                        </v-col>

                        <v-col
                          class="d-flex"
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            v-if="statut === 'Student'"
                            v-model="selected.description"
                            label="Group"
                            :rules="[rules.required]"
                            required
                          />
                          <v-text-field
                            v-if="statut === 'Professor'"
                            v-model="selected.description"
                            label="Discipline"
                            :rules="[rules.required]"
                            required
                          />
                        </v-col>

                        <v-spacer />
                        <v-col
                          class="d-flex ml-auto xsm"
                          cols="12"
                          sm="3"
                        >
                          <v-btn
                            x-large
                            block
                            color="success"
                            @click="editUser()"
                          >
                            Edit Student
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  const API_PATH = 'https://mps2-backend.herokuapp.com/api'

  export default {
    name: 'Students',
    data () {
      return {
        items: [],
        dialog: false,
        selected: { },
        statut: '',
        show1: false,
        statsList: ['Student', 'Professor', 'Admin'],
        rules: {
          required: (value) => !!value || 'Required.',
          min: (v) => (v && v.length >= 5) || 'Min 5 characters',
          max: (v) => (v && v.length <= 20) || 'Max 20 characters',
        },
      }
    },

    mounted () {
      this.getStudents()
    },

    methods: {
      getStudents () {
        axios
          .get(API_PATH + '/getStudents')
          .then((res) => {
            this.items = res.data.message
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },

      deleteUser (index) {
        axios
          .get(API_PATH + '/deleteUser?uid=' + this.items[index].username)
          .then((res) => {
            // console.log(res.data.status)
            if (res.data.status === 'succes') {
              // console.log('we did it')
              this.items.splice(index, 1)
            }
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },

      editUser () {
        axios
          .post(API_PATH + '/editUser', this.selected)
          .then((res) => {
            if (res.data.status === 'succes') {
              this.getStudents()
            }
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },
    },
  }
</script>
